/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM720M
 * 화면 설명: 미결명세조회
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.01.11
 * 작 성 자: 서영화 
 */
<template> 
  <ur-page-container  @action-search-click="fn_OpenPopup" @on-scroll-bottom="fn_LoadingData" @on-header-left-click="fn_ClickBackBtn"
                      class="msp" title="미결명세조회" :show-title="true" type="subpage"  action-type="search" :topButton="true">

    <!-- 고정영역 -->
    <template #frame-header-fixed>
      <ur-box-container alignV="start"  direction="column" class="msp-pm-wrap">
        <!-- 탭 -->
        <mo-tab-box :default-idx="'MSPPM721D'" :lv_ViewId="lv_ViewId" @tab-change="fn_TabHandler" ref="tabbox"  class="ns-move-tab-box w100">
          <mo-tab-content :idx="'MSPPM721D'" label="미지급액" ></mo-tab-content>
          <mo-tab-content :idx="'MSPPM724D'" label="고객정보" ></mo-tab-content>   
          <mo-tab-content :idx="'MSPPM726D'" label="계약관리" ></mo-tab-content>    
        </mo-tab-box>

        <!-- 디비전 공통영역 start -->
        <template>
          
          <!-- 검색결과 영역 start-->
          <ur-box-container  v-if="isSearched && !isCleared" componentid="ur-box-container_002" alignV="start" direction="column" class="search-result-box bd-b-Ty1"> 
            <strong class="tit">검색결과</strong>
            <mo-tab-box  default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
              <template v-for="item in searchedItems">
                <mo-tab-label v-if="!$bizUtil.isEmpty(item.text)" @click="fn_OpenPopup" :idx="item.idx" :key="item.idx" >
                  <!-- 검색결과 조건이름 -->
                  <span v-if="!$bizUtil.isEmpty(item.title)" class="tit">{{ item.title }}</span>
                  <!-- 검색결과 조건값 -->
                  <span class="txt">{{ item.text }}</span> 
                </mo-tab-label>
              </template>
            </mo-tab-box>
          </ur-box-container>
          <!-- 검색결과 영역 end-->

          <!-- 데이터개수 영역 start -->
          <ur-box-container alignV="start" componentid="ur-box-container_003" direction="column" class="ns-check-sum">
            <mo-list-item>
              <div  class="list-item__contents">
                <div class="list-item__contents__title">
                  <span v-if="checkedCount <= 0" class="sum">총 <strong class="crTy-blue3">{{ totalCount }}</strong> 명</span>
                  <span v-else class="sum"><strong class="crTy-blue3">{{ checkedCount }}</strong> 명 선택</span>
                </div>
              </div>
            </mo-list-item>
          </ur-box-container>
          <!-- 데이터개수 영역 end -->

        </template>
        <!-- 디비전 공통영역 end -->

      </ur-box-container>
    </template>

    <MSPPM721D v-if="lv_ViewId === 'MSPPM721D'" ref="MSPPM721D" :lv_ViewId="lv_ViewId" :lv_ParentViewId="lv_ParentViewId" @setChildData="fn_SetChildData"></MSPPM721D><!-- 미지급액 -->
    <MSPPM724D v-if="lv_ViewId === 'MSPPM724D'" ref="MSPPM724D" :lv_ViewId="lv_ViewId" :lv_ParentViewId="lv_ParentViewId" @setChildData="fn_SetChildData"></MSPPM724D><!-- 고객정보 -->
    <MSPPM726D v-if="lv_ViewId === 'MSPPM726D'" ref="MSPPM726D" :lv_ViewId="lv_ViewId" :lv_ParentViewId="lv_ParentViewId" @setChildData="fn_SetChildData"></MSPPM726D><!-- 계약관리 -->
  
  </ur-page-container>
</template>

<script>
import Msg       from '@/systems/webkit/msg/msg'
import MSPPM721D from '@/ui/pm/MSPPM721D.vue'     // 미지급액
import MSPPM724D from '@/ui/pm/MSPPM724D.vue'     // 고객정보
import MSPPM726D from '@/ui/pm/MSPPM726D.vue'     // 계약관리
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Screen from '~systems/mixin/screen'

export default {

  name: 'MSPPM720M', 
  screenId: 'MSPPM720M', 
  mixins: [Screen],
  components: {    
    MSPPM721D,
    MSPPM724D,
    MSPPM726D,
  },//componets,
  props:{
    viewId: '',
    callerId: '',
  },//props
  data () {
    return {

      title: '미결명세조회',

      lv_ViewId: '',      // 화면ID
      lv_ParentViewId: '',

      searchedItems: {},  // 검색결과 데이터

      /**
       * 제어 변수 
       */  
      isSearched: false,  // 조회이력 저장 변수
      isCleared: true,    // 조회조건 초기화 여부 

      /**
       * 체크박스 변수
       */
      totalCount: 0,      // 전체고객 수
      checkedCount: 0,    // 선택된 고객 수 

    }
  },//data

  created () {
   
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickBackBtn)     // backKey Event 등록
    window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')                       // post 호출 전 Progress 타입 설정

  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

    /**
     * 홈, 메뉴로 접근할 경우, '미지급액' 화면 호출 
     * 그 이외는 이전에 노출했던 화면 호출 
     */
    if(!_.isEmpty(this.$route.params)){
      this.lv_ViewId = 'MSPPM721D' // 기본화면(미지급액)
    }else{
      let storeData = this.getStore('pmStore').state.preSrnObjPM720M
      
      if(!_.isEmpty(storeData)){
        this.lv_ViewId =  storeData.page
      }else{
        this.lv_ViewId = 'MSPPM721D'
      }
    }

    console.log(this.getStore('pmStore').state.preSrnObjPM720M.page, this.lv_ViewId);

    // 탭메뉴 세팅
    setTimeout(() => {
      this.$refs.tabbox.setIdx(this.lv_ViewId)
    })

  },//mounted

  beforeDestroy () {
    
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickBackBtn) // backKey Event 해제

  },//beforeDestroy

  watch: {},//watch

  computed: {},//computed

  methods: {

   /**
    * @description 탭 핸들러이다.
    * @param       {String} viewId 디비전 화면ID
    */
    fn_TabHandler (viewId) {

      this.lv_ViewId       = viewId
      this.lv_ParentViewId =  this.$options.screenId

    },

   /**
    * @description 자식으로부터 받은 데이터를 저장한다.
    * @param       {Object} childData 자식 데이터 
    */
    fn_SetChildData(childData){
      this.isSearched    = childData.isSearched    // 조회이력 저장 변수
      this.searchedItems = childData.searchedItems // 검색조건 항목 리스트 
      this.totalCount    = childData.totalCount    // 전체고객 수
      this.checkedCount  = childData.checkedCount  // 선택된 고객 수 
      this.isCleared     = childData.isCleared     // 조회조건 초기화 여부 
    },

   /**
    * @description 디비전 화면의 뒤로가기 함수를 호출한다.
    */
    fn_ClickBackBtn () {

      if (this.checkedCount > 0) {
        this.fn_confirm()
        return 
      }
      
      switch(this.lv_ViewId){
        case 'MSPPM721D':
          this.$refs.MSPPM721D.fn_BackToPrevious()
          break
        case 'MSPPM724D':
          this.$refs.MSPPM724D.fn_BackToPrevious()
          break
        case 'MSPPM726D':
          this.$refs.MSPPM726D.fn_BackToPrevious()
          break
        default:
          break
      }

    },

    /**
     * @description 디비전 화면의 인피니트 로딩 함수를 호출한다.
     */
    fn_LoadingData(){

      switch(this.lv_ViewId){
        case 'MSPPM721D':
          this.$refs.MSPPM721D.fn_LoadingData()
          break
        case 'MSPPM724D':
          this.$refs.MSPPM724D.fn_LoadingData()
          break
        case 'MSPPM726D':
          this.$refs.MSPPM726D.fn_LoadingData()
          break
        default:
          break
      }

    },

   /**
    * @description 디비전 화면의 팝업 호출 함수를 호출한다.
    */
    fn_OpenPopup () {

      switch(this.lv_ViewId){
        case 'MSPPM721D':
          this.$refs.MSPPM721D.fn_OpenMSPPM722P()
          break
        case 'MSPPM724D':
          this.$refs.MSPPM724D.fn_OpenMSPPM725P()
          break
        case 'MSPPM726D':
          this.$refs.MSPPM726D.fn_OpenMSPPM727P()
          break
        default:
          break
      }

    },

    /**
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     */
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              switch(this.lv_ViewId){
                case 'MSPPM721D':
                  this.$refs.MSPPM721D.fn_BackToPrevious()
                  break
                case 'MSPPM724D':
                  this.$refs.MSPPM724D.fn_BackToPrevious()
                  break
                case 'MSPPM726D':
                  this.$refs.MSPPM726D.fn_BackToPrevious()
                  break
                default:
                  break
              }
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },

 },// methods

}//export default


</script>